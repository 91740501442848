import React from "react";
import { ConfigAppSDK } from "@contentful/app-sdk";
import { Flex, Heading, Note } from "@contentful/f36-components";
import tokens from "@contentful/f36-tokens";
import { useSDK } from "@contentful/react-apps-toolkit";
import { styles } from "../../locations/styles";
import { useFetchDeeplLocales } from "../../services";
import { mapLocale } from "../../utils";

export const SupportedLocales: React.FC = () => {
    const sdk = useSDK<ConfigAppSDK>();
    const deeplCredentials = sdk.parameters.installation.deeplApiKey;

    const { deeplTargetLocales } = useFetchDeeplLocales({ skip: !deeplCredentials });

    if (!deeplTargetLocales) {
        return null;
    }

    return (
        <Flex flexDirection="column" flexWrap="wrap" css={styles.localesWrapper}>
            <Heading css={styles.spaced}>Supported Locales</Heading>
            <Flex flexWrap="wrap" gap={tokens.spacingM} css={styles.localesContainer}>
                {Object.keys(sdk.locales.names)
                    .map(locale => ({
                        locale,
                        mappedLocale: mapLocale(locale, deeplTargetLocales),
                    }))
                    .map(({ locale, mappedLocale }) => (
                        <Note
                            key={locale}
                            variant={mappedLocale ? "positive" : "negative"}
                            title={
                                mappedLocale
                                    ? `The locale "${locale}" can be translated ("${mappedLocale}" from DeepL).`
                                    : `The locale "${locale}" cannot be translated (no match found in DeepL).`
                            }
                        />
                    ))}
            </Flex>
        </Flex>
    );
};
