import { useContext } from "react";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { TranslationContext } from "./";

export const useTranslationContext = () => {
    const sdk = useSDK<SidebarAppSDK>();
    const context = useContext(TranslationContext);

    if (!context) {
        sdk.notifier.error(
            "useTranslationContext must be used within a TranslationContextProvider"
        );
    }

    const [state, dispatch] = context;

    return {
        state,
        dispatch,
    };
};
