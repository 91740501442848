import { useCallback } from "react";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { ACTION_TYPE, useTranslationContext } from "../context";
import { useDeeplTranslate, useFetchDeeplUsage } from "../services";
import { handleFieldTypesAfterTranslation, handleFieldTypesBeforeTranslation } from "../utils";

export const useTranslation = () => {
    const sdk = useSDK<SidebarAppSDK>();
    const { deeplTranslate } = useDeeplTranslate();
    const { state, dispatch } = useTranslationContext();
    const { translationRequests, formality } = state;
    const { mutate: usageMutation } = useFetchDeeplUsage();

    const resetTranslationRequest = useCallback(() => {
        dispatch({
            type: ACTION_TYPE.SET_TRANSLATION_REQUEST,
            payload: [],
        });
    }, [dispatch]);

    const setHasFieldChanged = useCallback(
        (hasChanged: boolean) => {
            dispatch({
                type: ACTION_TYPE.SET_HAS_FIELD_CHANGED,
                payload: hasChanged,
            });
        },
        [dispatch]
    );

    const translate = useCallback(async (): Promise<void> => {
        try {
            setHasFieldChanged(false);

            const promises = translationRequests.flatMap(translationItem =>
                translationItem.to
                    .filter(toLocale => toLocale !== translationItem.from)
                    .map(async toLocale => {
                        const normalizedBeforeTranslation = await handleFieldTypesBeforeTranslation(
                            translationItem.fieldId,
                            translationItem.value,
                            sdk
                        );

                        const translated = await deeplTranslate(
                            normalizedBeforeTranslation,
                            translationItem.from,
                            toLocale,
                            translationItem.formality || formality
                        );

                        const normalizedAfterTranslation = await handleFieldTypesAfterTranslation(
                            translationItem.fieldId,
                            translated,
                            sdk
                        );

                        await sdk.entry.fields[translationItem.fieldId].setValue(
                            normalizedAfterTranslation,
                            toLocale
                        );
                    })
            );

            await Promise.all(promises);
            usageMutation();
            resetTranslationRequest();
        } catch (error) {
            if (error instanceof Error) {
                sdk.notifier.error(error.message);
            } else {
                sdk.notifier.error("unknown error was occurred");
            }
        } finally {
            setHasFieldChanged(true);
        }
    }, [
        deeplTranslate,
        formality,
        resetTranslationRequest,
        sdk,
        setHasFieldChanged,
        translationRequests,
        usageMutation,
    ]);

    return { translate };
};
