import React, { useReducer } from "react";
import { TranslationContext, translationReducer } from "./";
import { ContextDefaultValue, State } from "./types";

interface TranslationContextProviderProps {
    initialState: State;
}

export const TranslationContextProvider: React.FC<
    React.PropsWithChildren<TranslationContextProviderProps>
> = ({ initialState, children }) => {
    const [state, dispatch] = useReducer(translationReducer, initialState);

    const value: ContextDefaultValue = [state, dispatch];

    return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
};
