import { KeyValueMap } from "contentful-management";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { FieldId, documentToHtml } from "../lib";

export const handleFieldTypesBeforeTranslation = (
    field: FieldId,
    value: any,
    sdk: SidebarAppSDK<KeyValueMap, KeyValueMap>
) => {
    const sdkField = sdk.entry.fields[field];
    const fieldType = sdkField.type;

    if ("RichText" === fieldType) {
        return documentToHtml(value);
    }

    if ("Array" === fieldType) {
        if (undefined === value) {
            return "[]";
        }

        // we can't use JSON.stringify as there is no space between each comma and deepl is then not able to
        // translate the string.
        return `["${value.join('", "')}"]`;
    }

    return value;
};
