import { useCallback } from "react";
import { useSDK } from "@contentful/react-apps-toolkit";
import { useTranslationContext } from "../context";
import { Formality, Locale } from "../lib";
import { mapLocale } from "../utils";
import { usePostDeepL } from "./";

export const useDeeplTranslate = () => {
    const sdk = useSDK();
    const { state } = useTranslationContext();
    const { postDeepl } = usePostDeepL();

    const { deeplSourceLocales, deeplTargetLocales } = state;
    const deeplTranslate = useCallback(
        async (
            text: string,
            sourceLang: Locale = "de",
            targetLang: Locale = "en",
            options?: Formality
        ): Promise<string> => {
            try {
                const mappedLocaleSource = mapLocale(sourceLang, deeplSourceLocales);
                const mappedLocaleTarget = mapLocale(targetLang, deeplTargetLocales);

                if (mappedLocaleSource === false) {
                    sdk.notifier.error(`Locale ${sourceLang} unsupported SourceLang.`);
                    return "";
                }

                if (mappedLocaleTarget === false) {
                    sdk.notifier.error(`Locale ${targetLang} unsupported TargetLang.`);
                    return "";
                }

                const searchParams = new URLSearchParams({
                    text,
                    source_lang: mappedLocaleSource ?? "",
                    target_lang: mappedLocaleTarget,
                    tag_handling: "html",
                    formality: options || "default",
                });

                const response = await postDeepl("translate", searchParams);

                if (response && response.translations && response.translations[0]) {
                    return response.translations[0].text;
                } else {
                    return "";
                }
            } catch (error) {
                if (error instanceof Error) {
                    sdk.notifier.error(error.message);
                } else {
                    sdk.notifier.error("Unknown error occurred");
                }

                return "";
            }
        },
        [deeplSourceLocales, deeplTargetLocales, postDeepl, sdk.notifier]
    );

    return {
        deeplTranslate,
    };
};
