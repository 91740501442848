import { KeyValueMap } from "contentful-management";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { FieldId, htmlToDocument } from "../lib";

export const handleFieldTypesAfterTranslation = (
    field: FieldId,
    value: any,
    sdk: SidebarAppSDK<KeyValueMap, KeyValueMap>
) => {
    const sdkField = sdk.entry.fields[field];
    const fieldType = sdkField.type;

    if ("RichText" === sdk.entry.fields[field].type) {
        return htmlToDocument(value);
    }

    if ("Array" === fieldType) {
        if (undefined === value) {
            return [];
        }

        try {
            return JSON.parse(value);
        } catch (error) {
            if (error instanceof Error) {
                sdk.notifier.error(error.message);
            } else {
                sdk.notifier.error("unknown error was occurred");
            }

            return [];
        }
    }

    return value;
};
