import tokens from "@contentful/f36-tokens";
import { css } from "@emotion/react";

export const styles = {
    body: css({
        height: "auto",
        minHeight: "65vh",
        margin: `${tokens.spacingXl} auto 0 auto`,
        padding: `${tokens.spacingXl} ${tokens.spacing2Xl}`,
        maxWidth: tokens.contentWidthText,
        backgroundColor: tokens.colorWhite,
        zIndex: tokens.zIndexDefault,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "2px",
    }),
    splitter: css({
        margin: `${tokens.spacingL} 0`,
        border: 0,
        height: 1,
        backgroundColor: tokens.gray300,
    }),
    background: css({
        display: "block",
        position: "absolute",
        zIndex: tokens.zIndexNegative,
        top: 0,
        width: tokens.contentWidthFull,
        height: 300,
        backgroundColor: "#f8ab00",
    }),
    localesWrapper: css({
        flexDirection: "column",
        flexWrap: "wrap",
        gap: tokens.spacingM,
        width: tokens.contentWidthFull,
        alignItems: "start",
    }),
    localesContainer: css({
        alignItems: "start",
        flexWrap: "wrap",
        gap: tokens.spacingM,
        width: tokens.contentWidthFull,
    }),
    spaced: css({
        marginBottom: tokens.spacingL,
    }),
    logoContainer: css({
        justifyContent: "center",
        margin: `${tokens.spacing2Xl} 0 ${tokens.spacing4Xl}`,
    }),
    logo: css({
        fontSize: tokens.fontSizeS,
        lineHeight: tokens.lineHeightS,
    }),
};
