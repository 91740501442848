import { useMemo } from "react";
import { SWRConfig } from "swr";
import { locations } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { localeStorageStore } from "./lib";
import { ConfigScreen, SidebarWrapper } from "./locations";

export const App = () => {
    const sdk = useSDK();

    const Component = useMemo(() => {
        if (sdk.location.is(locations.LOCATION_ENTRY_SIDEBAR)) {
            return SidebarWrapper;
        }

        if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
            return ConfigScreen;
        }
    }, [sdk.location]);

    return Component ? (
        <SWRConfig
            value={{
                provider: localeStorageStore,
                revalidateOnFocus: false,
                revalidateIfStale: false,
                shouldRetryOnError: false,
            }}>
            <Component />
        </SWRConfig>
    ) : null;
};
