import { Dispatch } from "react";
import {
    FieldId,
    Formality,
    LabeledLocale,
    Locale,
    TranslatableField,
    TranslationValue,
} from "../lib";
import { UsageResponse } from "../services/useFetchDeeplUsage";

export interface TranslationRequestItem {
    fieldId: FieldId;
    label: string;
    from: Locale;
    to: Locale[];
    formality: Formality;
    value: TranslationValue;
}

export type TranslationRequests = TranslationRequestItem[];

export interface State {
    translationRequests: TranslationRequests;
    formality: Formality;
    contentfulLocales: LabeledLocale[];
    deeplSourceLocales: Locale[];
    deeplTargetLocales: Locale[];
    hasFieldChanged: boolean;
    usage: UsageResponse;
}

export const defaultState: State = {
    translationRequests: [],
    formality: "default",
    contentfulLocales: [],
    deeplSourceLocales: [],
    deeplTargetLocales: [],
    hasFieldChanged: true,
    usage: {
        character_count: 0,
        character_limit: 0,
    },
};
interface HandleFieldChangePayload {
    field: TranslatableField;
    locale: Locale;
    value: TranslationValue;
    formality: Formality;
    targetLocales: Locale[];
    translationRequests: TranslationRequests;
}
export type ContextDefaultValue = [State, Dispatch<Action>];

export type Action =
    | {
          type: ACTION_TYPE.SET_TRANSLATION_TO;
          payload: {
              fieldId: FieldId;
              toLocale: Locale;
          };
      }
    | { type: ACTION_TYPE.SET_TRANSLATION_FROM; payload: { fieldId: FieldId; fromLocale: Locale } }
    | { type: ACTION_TYPE.SET_FORMALITY; payload: { fieldId: FieldId; formality: Formality } }
    | {
          type: ACTION_TYPE.SET_TRANSLATION_REQUEST;
          payload: TranslationRequests;
      }
    | { type: ACTION_TYPE.SET_HAS_FIELD_CHANGED; payload: boolean }
    | { type: ACTION_TYPE.HANDLE_FIELD_CHANGE; payload: HandleFieldChangePayload };

export enum ACTION_TYPE {
    SET_TRANSLATION_TO = " SET_TRANSLATION_TO",
    SET_TRANSLATION_FROM = "SET_TRANSLATION_FROM",
    SET_FORMALITY = "SET_FORMALITY",
    SET_TRANSLATION_REQUEST = "SET_TRANSLATION_REQUEST",
    SET_HAS_FIELD_CHANGED = "SET_HAS_FIELD_CHANGED",
    HANDLE_FIELD_CHANGE = "HANDLE_FIELD_CHANGE",
}
