import { useCallback } from "react";
import { useSDK } from "@contentful/react-apps-toolkit";
import { getPlanFromCredentials } from "../lib/deepl";

export const usePostDeepL = () => {
    const sdk = useSDK();
    const deeplCredentials = sdk.parameters.installation.deeplApiKey;

    const postDeepl = useCallback(
        async (path: string, body: URLSearchParams | null = null): Promise<any> => {
            if (!deeplCredentials) {
                return;
            }

            try {
                const plan = getPlanFromCredentials(deeplCredentials);

                const rawResponse = await fetch(`/api/${plan}/${path}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `DeepL-Auth-Key ${deeplCredentials}`,
                    },
                    body: body?.toString(),
                });

                if (!rawResponse.ok) {
                    sdk.notifier.error(`Translation error!  Status code: ${rawResponse.status}`);
                }

                return await rawResponse.json();
            } catch (error) {
                if (error instanceof Error) {
                    sdk.notifier.error(error.message);
                } else {
                    sdk.notifier.error("unknown error was occurred");
                }
            }
        },
        [deeplCredentials, sdk.notifier]
    );
    return { postDeepl };
};
