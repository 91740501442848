import useSWR from "swr";
import { KnownAppSDK } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { Locale } from "../lib";
import { getPlanFromCredentials } from "../lib/deepl";

export interface DeepLLocaleProps {
    skip?: boolean;
}

export interface DeepLLocaleResponse {
    language: Locale;
    name: string;
    supports_formality: boolean;
}

const DeeplLocalesFetcher = async (
    credentials: string,
    type: "source" | "target",
    sdk: KnownAppSDK
): Promise<undefined | Locale[]> => {
    try {
        const plan = getPlanFromCredentials(credentials);

        const response = await fetch(`/api/${plan}/languages?type=${type}`, {
            method: "GET",
            headers: {
                Authorization: `DeepL-Auth-Key ${credentials}`,
                "Content-Type": "application/json",
                Accept: "*/*",
                "Accept-Encoding": "gzip, deflate, br",
                Connection: "keep-alive",
            },
        });

        if (!response.ok) {
            sdk.notifier.error(`Error fetching DeepL ${type} status: ${response.status}`);

            return undefined;
        }

        const data: DeepLLocaleResponse[] = await response.json();

        return data.map(locale => locale.language);
    } catch (error) {
        if (error instanceof Error) {
            sdk.notifier.error(error.message);
        } else {
            sdk.notifier.error("unknown error was occurred");
        }

        return undefined;
    }
};

export const useFetchDeeplLocales = (props?: DeepLLocaleProps) => {
    const { skip } = props ?? {};
    const sdk = useSDK();
    const deeplCredentials = sdk.parameters.installation.deeplApiKey;

    const { data: deeplSourceLocales, isLoading: isDeeplSourceLocalesLoading } = useSWR(
        ["fetchSourceDeeplLocales", deeplCredentials],
        () => (!skip ? DeeplLocalesFetcher(deeplCredentials, "source", sdk) : null)
    );

    const { data: deeplTargetLocales, isLoading: isDeeplTargetLocalesLoading } = useSWR(
        ["fetchTargetDeeplLocales", deeplCredentials],
        () => (!skip ? DeeplLocalesFetcher(deeplCredentials, "target", sdk) : null)
    );

    return {
        deeplSourceLocales,
        deeplTargetLocales,
        isDeeplSourceLocalesLoading,
        isDeeplTargetLocalesLoading,
    };
};
