import React, { useCallback } from "react";
import { Select } from "@contentful/f36-components";
import { useTranslationContext } from "../../context";
import { ACTION_TYPE, TranslationRequestItem } from "../../context/types";
import { FieldId, LabeledLocale, Locale } from "../../lib";

interface FromLocaleSelectProps {
    translationLocales: LabeledLocale[];
    translation: TranslationRequestItem;
}

export const FromLocaleSelect: React.FC<FromLocaleSelectProps> = ({
    translation,
    translationLocales,
}) => {
    const { dispatch } = useTranslationContext();

    const setTranslationFrom = useCallback(
        (fieldId: FieldId, fromLocale: Locale) => {
            dispatch({ type: ACTION_TYPE.SET_TRANSLATION_FROM, payload: { fieldId, fromLocale } });
        },
        [dispatch]
    );
    return (
        <Select
            onChange={event => setTranslationFrom(translation.fieldId, event.target.value)}
            value={translation.from}>
            {translationLocales.map(locale => (
                <Select.Option key={locale.locale} value={locale.locale}>
                    {locale.label}
                </Select.Option>
            ))}
        </Select>
    );
};
