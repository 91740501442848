import { useCallback, useMemo } from "react";
import { SidebarAppSDK } from "@contentful/app-sdk";
import { useSDK } from "@contentful/react-apps-toolkit";
import { useTranslationContext } from "../context";
import { ACTION_TYPE } from "../context/types";
import { Locale } from "../lib";
import { mapLocale } from "../utils";

type Detacher = () => void;

export const useFieldChangeHandler = () => {
    const sdk = useSDK<SidebarAppSDK>();
    const { state, dispatch } = useTranslationContext();
    const {
        formality,
        contentfulLocales,
        translationRequests,
        deeplTargetLocales,
        hasFieldChanged,
        usage,
    } = state;

    const translatableFields = useMemo(() => {
        return Object.keys(sdk.entry.fields)
            .filter(key => {
                const field = sdk.entry.fields[key];

                if (!field) {
                    return false;
                }

                const { locales, type: fieldType } = field;

                if (locales.length <= 1) {
                    return false;
                }

                switch (fieldType) {
                    case "Link":
                    case "Array":
                    case "Boolean":
                    case "Date":
                    case "Object":
                    case "Location":
                        return false;
                    default:
                        return true;
                }
            })
            .map(key => {
                return {
                    id: sdk.entry.fields[key].id,
                    label: sdk.entry.fields[key].name,
                };
            });
    }, [sdk.entry.fields]);

    const targetLocales: Locale[] = useMemo(() => {
        return [
            ...contentfulLocales
                .map(locale => locale.locale)
                .filter(locale => mapLocale(locale, deeplTargetLocales) !== false),
        ];
    }, [contentfulLocales, deeplTargetLocales]);

    const fieldChangeHandler = useCallback(() => {
        const detachers: Detacher[] = [];

        translatableFields.forEach(field => {
            contentfulLocales.forEach(locale => {
                let skipFirst = true;

                const detach = sdk.entry.fields[field.id].onValueChanged(locale.locale, value => {
                    if (skipFirst) {
                        skipFirst = false;
                        return;
                    }

                    if (!hasFieldChanged || usage?.character_count === usage?.character_limit) {
                        return;
                    }

                    dispatch({
                        type: ACTION_TYPE.HANDLE_FIELD_CHANGE,
                        payload: {
                            field,
                            locale: locale.locale,
                            value,
                            formality,
                            targetLocales,
                            translationRequests,
                        },
                    });
                });

                detachers.push(detach);
            });
        });

        return detachers;
    }, [
        translatableFields,
        contentfulLocales,
        sdk.entry.fields,
        hasFieldChanged,
        usage,
        dispatch,
        formality,
        targetLocales,
        translationRequests,
    ]);

    return {
        fieldChangeHandler,
    };
};
