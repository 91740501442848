import { formalSpeechOptions } from "./constants";

export type FieldId = string;

export type Locale = string | "de" | "en";

export enum INLINES {
    EMBEDDED_ENTRY = "embedded-entry-inline",
    ENTRY_HYPERLINK = "entry-hyperlink",
    ASSET_HYPERLINK = "asset-hyperlink",
}

export type Formality = (typeof formalSpeechOptions)[number]["key"];

export type LabeledLocale = {
    locale: Locale;
    label: String;
};

export type AppInstallationParameters = {
    deeplApiKey: string;
    formalSpeech: Formality;
    masked: boolean;
};

export type TranslatableField = {
    id: string;
    label: string;
};

export type TranslationValue = {
    nodeType: string;
    data: object;
    content: ContentNode[];
};

export interface ContentNode extends Node {
    content: Node[];
}
