import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Document } from "@contentful/rich-text-types/dist/types/types";
import { INLINES } from "../../types";
import { renderBlockEmbeddedAsset } from "./block-embedded-asset";
import { renderEmbeddedEntry } from "./inline-embedded-entry";
import { renderLink } from "./inline-hyperlink";

export const documentToHtml = (value: Document): string => {
    return documentToHtmlString(value, {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: renderBlockEmbeddedAsset,
            [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
            [INLINES.EMBEDDED_ENTRY]: renderEmbeddedEntry,
            [INLINES.ENTRY_HYPERLINK]: renderLink,
            [INLINES.ASSET_HYPERLINK]: renderLink,
        },
    });
};
