import { createRoot } from "react-dom/client";
import { GlobalStyles } from "@contentful/f36-components";
import { SDKProvider } from "@contentful/react-apps-toolkit";
import { App } from "./App";

const container = document.getElementById("root");

if (container) {
    const root = createRoot(container);

    root.render(
        <SDKProvider>
            <GlobalStyles />
            <App />
        </SDKProvider>
    );
} else {
    console.error("Failed to find the root element to mount the application.");
}
