import React from "react";
import { Checkbox } from "@contentful/f36-components";
import { Multiselect } from "@contentful/f36-multiselect";
import { useTranslationContext } from "../../context";
import { ACTION_TYPE, TranslationRequestItem } from "../../context/types";
import { LabeledLocale } from "../../lib";

interface ToLocaleSelectProps {
    translation: TranslationRequestItem;
    translationLocales: LabeledLocale[];
}

export const ToLocaleSelect: React.FC<ToLocaleSelectProps> = ({
    translation,
    translationLocales,
}) => {
    const { dispatch } = useTranslationContext();

    const availableLocales = translationLocales.filter(
        locale => translation.from !== locale.locale
    );

    const setTranslationTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        dispatch({
            type: ACTION_TYPE.SET_TRANSLATION_TO,
            payload: {
                fieldId: translation.fieldId,
                toLocale: value,
            },
        });
    };

    return (
        <>
            {translationLocales.length > 3 ? (
                <Multiselect
                    currentSelection={translation.to.filter(locale => translation.from !== locale)}
                    popoverProps={{ isFullWidth: true }}>
                    {availableLocales.map((locale, key) => (
                        <Multiselect.Option
                            key={key}
                            itemId={`${translation.fieldId}-${locale.locale}`}
                            value={locale.locale}
                            label={locale.label as string}
                            onSelectItem={setTranslationTo}
                            isChecked={translation.to.includes(locale.locale)}
                        />
                    ))}
                </Multiselect>
            ) : (
                <Checkbox.Group value={translation.to as string[]} onChange={setTranslationTo}>
                    {availableLocales.map(locale => (
                        <Checkbox key={locale.locale} value={locale.locale}>
                            {locale.label}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            )}
        </>
    );
};
