import React from "react";
import { FormControl, Select } from "@contentful/f36-components";
import { formalSpeechOptions } from "../lib";
import { isFormalityEnabled } from "../lib/flags/formality";

interface FormalSpeechSelectProps {
    value: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

export const FormalSpeechSelect: React.FC<FormalSpeechSelectProps> = ({ value, onChange }) => {
    if (!isFormalityEnabled) {
        return null;
    }

    return (
        <FormControl>
            <FormControl.Label>Formal Speech</FormControl.Label>
            <Select value={value} onChange={onChange}>
                {formalSpeechOptions.map(speech => (
                    <Select.Option key={speech.key} value={speech.key}>
                        {speech.label}
                    </Select.Option>
                ))}
            </Select>
        </FormControl>
    );
};
