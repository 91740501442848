import { FieldId, Locale } from "../lib";
import { ACTION_TYPE, Action, State, TranslationRequestItem, TranslationRequests } from "./types";

const toggleLocaleInLocalesArray = (targetLocales: Locale[], toLocale: Locale): Locale[] => {
    const localeIndex = targetLocales.indexOf(toLocale);

    if (localeIndex !== -1) {
        return targetLocales.filter((_, index) => index !== localeIndex);
    } else {
        return [...targetLocales, toLocale];
    }
};

const findTranslationRequestIndex = (
    translationRequests: TranslationRequests,
    fieldId: FieldId
) => {
    return translationRequests.findIndex(translation => translation.fieldId === fieldId);
};

const updateTranslationRequestItem = (
    index: number,
    translationRequests: TranslationRequests,
    updatedTranslationItem: Partial<TranslationRequestItem>
) => {
    return [
        ...translationRequests.slice(0, index),
        {
            ...translationRequests[index],
            ...updatedTranslationItem,
        },
        ...translationRequests.slice(index + 1),
    ];
};

export const translationReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION_TYPE.SET_TRANSLATION_FROM: {
            const { fieldId, fromLocale } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            if (index === -1 || state.translationRequests[index].from === fromLocale) {
                return state;
            }

            return {
                ...state,
                translationRequests: updateTranslationRequestItem(
                    index,
                    state.translationRequests,
                    { from: fromLocale }
                ),
            };
        }

        case ACTION_TYPE.SET_TRANSLATION_TO: {
            const { fieldId, toLocale } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            const updatedToLocales = toggleLocaleInLocalesArray(
                state.translationRequests[index].to,
                toLocale
            );

            return {
                ...state,
                translationRequests: updateTranslationRequestItem(
                    index,
                    state.translationRequests,
                    {
                        to: updatedToLocales,
                    }
                ),
            };
        }
        case ACTION_TYPE.SET_FORMALITY: {
            const { fieldId, formality } = action.payload;
            const index = findTranslationRequestIndex(state.translationRequests, fieldId);

            return {
                ...state,
                translationRequests: updateTranslationRequestItem(
                    index,
                    state.translationRequests,
                    {
                        formality: formality,
                    }
                ),
            };
        }
        case ACTION_TYPE.SET_TRANSLATION_REQUEST: {
            return {
                ...state,
                translationRequests: action.payload,
            };
        }
        case ACTION_TYPE.SET_HAS_FIELD_CHANGED: {
            return {
                ...state,
                hasFieldChanged: action.payload,
            };
        }
        case ACTION_TYPE.HANDLE_FIELD_CHANGE: {
            const { field, formality, locale, targetLocales, translationRequests, value } =
                action.payload;

            const index = findTranslationRequestIndex(translationRequests, field.id);

            const toLocales: Locale[] =
                translationRequests[index] &&
                translationRequests[index].to.length !== targetLocales.length
                    ? translationRequests[index].to
                    : targetLocales;

            const translationEntry = {
                fieldId: field.id,
                formality,
                from: locale,
                label: field.label,
                to: toLocales,
                value: value,
            };

            let updatedTranslationRequest = [...translationRequests];

            if (index !== -1) {
                updatedTranslationRequest[index] = translationEntry;
            } else {
                updatedTranslationRequest = [...translationRequests, translationEntry];
            }
            return {
                ...state,
                translationRequests: updatedTranslationRequest,
            };
        }
        default:
            return state;
    }
};
