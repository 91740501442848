import useSWR from "swr";
import { usePostDeepL } from "./";

export interface UsageProps {
    skip?: boolean;
}

export interface UsageResponse {
    character_count: number;
    character_limit: number;
}

const DeeplUsagefetcher = async (
    postDeepl: (path: string, body?: URLSearchParams | null) => Promise<any>
): Promise<UsageResponse> => {
    return await postDeepl("usage");
};

export const useFetchDeeplUsage = (props?: UsageProps) => {
    const { skip } = props ?? {};

    const { postDeepl } = usePostDeepL();

    const { data, error, isLoading, mutate } = useSWR(["deeplUsage", skip], () =>
        !skip ? DeeplUsagefetcher(postDeepl) : null
    );

    return {
        data,
        error,
        isLoading,
        mutate,
    };
};
