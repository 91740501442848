import { Locale } from "../lib";

export const mapLocale = (locale: Locale, deeplLocales: Locale[] | undefined) => {
    if (deeplLocales) {
        let localeUpperCase: Locale = locale.toUpperCase();

        if (deeplLocales.includes(localeUpperCase)) {
            return localeUpperCase;
        }

        localeUpperCase = localeUpperCase.substring(0, 2);

        if (deeplLocales.includes(localeUpperCase)) {
            return localeUpperCase;
        }
    }
    return false;
};
