import { Fragment, useCallback, useMemo } from "react";
import { Button, Card, FormControl, Stack, Subheading } from "@contentful/f36-components";
import { ACTION_TYPE, useTranslationContext } from "../context";
import { useTranslation } from "../hooks";
import { FieldId, Formality, LabeledLocale } from "../lib";
import { mapLocale } from "../utils";
import { FormalSpeechSelect, FromLocaleSelect, ToLocaleSelect } from "./";

export const TranslationCard = () => {
    const { state, dispatch } = useTranslationContext();
    const { translate } = useTranslation();

    const { translationRequests, formality, contentfulLocales, deeplTargetLocales } = state;

    const setFormality = useCallback(
        (fieldId: FieldId, formality: Formality) => {
            dispatch({ type: ACTION_TYPE.SET_FORMALITY, payload: { fieldId, formality } });
        },
        [dispatch]
    );

    const translationLocales: LabeledLocale[] = useMemo(() => {
        return contentfulLocales.filter(
            locale => mapLocale(locale.locale, deeplTargetLocales) !== false
        );
    }, [contentfulLocales, deeplTargetLocales]);

    const resetTranslationRequest = useCallback(() => {
        dispatch({
            type: ACTION_TYPE.SET_TRANSLATION_REQUEST,
            payload: [],
        });
    }, [dispatch]);

    return (
        <Card>
            {translationRequests.map(translation => (
                <Fragment key={`${translation.fieldId}-${translation.from}`}>
                    <Subheading>{translation.label}</Subheading>
                    <FormControl as="fieldset">
                        <FormControl.Label as="legend">Translate From</FormControl.Label>
                        <FromLocaleSelect
                            translation={translation}
                            translationLocales={translationLocales}
                        />
                    </FormControl>
                    <FormControl as="fieldset">
                        <FormControl.Label as="legend">Translate To</FormControl.Label>
                        <ToLocaleSelect
                            translation={translation}
                            translationLocales={translationLocales}
                        />
                    </FormControl>
                    <FormControl as="fieldset">
                        <FormalSpeechSelect
                            value={translation.formality || formality}
                            onChange={e =>
                                setFormality(translation.fieldId, e.target.value as Formality)
                            }
                        />
                    </FormControl>
                </Fragment>
            ))}
            <Stack>
                <Button variant="primary" onClick={translate}>
                    Translate
                </Button>
                <Button variant="transparent" onClick={resetTranslationRequest}>
                    Cancel
                </Button>
            </Stack>
        </Card>
    );
};
