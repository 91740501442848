import React, { useEffect } from "react";
import { Flex, Notification, Paragraph } from "@contentful/f36-components";
import { useSDK } from "@contentful/react-apps-toolkit";
import { useFetchDeeplUsage } from "../services/index";

export const DeeplUsageWidget: React.FC = () => {
    const sdk = useSDK();
    const deeplCredentials = sdk.parameters.installation.deeplApiKey;

    const { data, error } = useFetchDeeplUsage({ skip: !deeplCredentials });

    useEffect(() => {
        if (!deeplCredentials) {
            return;
        }

        if (error) {
            Notification.error("Error fetching Usage");
        }
    }, [deeplCredentials, error]);

    if (!data) {
        return null;
    }

    const { character_count, character_limit } = data;

    return (
        <Flex>
            <Paragraph>
                {character_count.toLocaleString()} / {character_limit.toLocaleString()} chars used (
            </Paragraph>
            <Paragraph>{((100 * character_count) / character_limit).toFixed(2)}%)</Paragraph>
        </Flex>
    );
};
